<script>
export default {
  name: 'OsLoader',
  props: {
    size: {
      type: Number,
      default: 18,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<template>
  <div class="d-flex justify-content-center overflow-hidden">
    <img v-if="width === 0" :style="{height: `${size}px`}" alt="" src="../assets/images/loaders/loader2.svg" />
    <img v-else :style="{width: `${width}px`}" alt="" src="../assets/images/loaders/loader2.svg" />
    <!--    <div class="custom-loader"></div>-->
  </div>
</template>

<style lang="scss" scoped>
.custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #0095e8 94%, #0000) top/5px 5px no-repeat,
    conic-gradient(#0000 30%, #0095e8);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  animation: s3 0.8s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
