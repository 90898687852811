import dayjs from 'dayjs';
import BoardService from '@/services/board.service';

export const handlerDateTime = date => {
  return dayjs(date).format('MMMM D, YYYY h:mm A');
};

export const formatColor = task => {
  const el = task || {color: '#a1a1a1'};
  return {
    background: `${el.color}30`,
    color: `${el.color}`,
  };
};

export const formatEstimate = number => {
  return `${Math.floor(number / 60)}h ${number % 60}m`;
};

export const wordToUpperCase = word => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

export const getStatusesForProject = project => {
  if (project) {
    this.statuses = [];
    BoardService.getProjectStatusesForTask(project.id).then(r => {
      this.statuses = r.data;
    });
  }
};

export const colorStatus = status => {
  if (status === 'in_work') return 'primary';
  if (status === 'qa') return 'warning';
  if (status === 'review') return 'warning';
  if (status === 'release') return 'info';
  return 'secondary';
};

export const resolveTypeVariant = type => {
  if (type === 'epic') return 'info';
  if (type === 'task') return 'primary';
  if (type === 'bug') return 'danger';
  return 'primary';
};
