<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <div class="tw-flex md:tw-items-end tw-justify-between tw-pr-1 max-md:tw-flex-col">
      <div>
        <h3 class="tw-mb-0 tw-font-semibold tw-text-gray-700">{{ $t('Sprint analytics') }}</h3>
        <div v-if="sprint" class="tw-w-full">
          Sprint #{{ sprint.name }} / Project: {{ sprint.project.title }} /
          <span>
            {{ formatDateTime(sprint.date_start) }}
            &nbsp;&#8211;&nbsp;
            {{ formatDateTime(sprint.date_end) }}
          </span>
        </div>
      </div>
      <div v-if="!isLoadingAnalytics" class="tw-flex tw-items-center max-md:tw-justify-end max-md:tw-mt-3 tw-gap-x-3">
        <span
          v-if="selectedSprint || selectedUser || selectedStatus"
          class="hover:tw-text-sky-500 tw-transition tw-font-medium tw-cursor-pointer"
          @click="
            () => {
              getSprint();
              selectedSprint = null;
              selectedUser = null;
              selectedStatus = null;
              dateRange = [null, null];
            }
          "
          >Clear</span
        >
        <!--        <MultiSelect-->
        <!--          v-model="selectedSprint"-->
        <!--          :options="sprints.map(el => ({title: `#${el.name} ${el.goal}`, value: el.id}))"-->
        <!--          optionLabel="title"-->
        <!--          placeholder="Select sprint"-->
        <!--        />-->
        <UIDropdown
          v-model="selectedSprint"
          :values="sprints.map(el => ({title: `#${el.name} ${el.goal}`, value: el.id}))"
          position="tw-right-0"
          title-button="Sprint"
          @on-select="onSelectSprint"
        />
        <date-picker
          v-model="dateRange"
          :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
          :lang="{
            formatLocale: {
              firstDayOfWeek: 1,
            },
          }"
          class="tw-w-[200px] range-date-input"
          placeholder="Date range"
          prefix-class="xmx"
          range
          type="date"
          value-type="YYYY-MM-DD HH:mm"
          @change="getSprint"
        >
          <template v-slot:icon-calendar>
            <font-ai icon="fa-regular fa-calendar" />
          </template>
          <template v-slot:icon-clear>
            <font-ai icon="fa-solid fa-xmark" />
          </template>
        </date-picker>
        <UIDropdown
          v-model="selectedStatus"
          :values="statuses"
          position="tw-right-0"
          title-button="Status"
          @on-select="getSprint"
        />
        <UIDropdown
          v-model="selectedUser"
          :values="users"
          position="tw-right-0"
          title-button="Assigned"
          @on-select="getSprint"
        />
      </div>
    </div>

    <OsLoader v-if="isLoadingAnalytics" class="tw-mt-4" />

    <div class="tw-flex-grow custom-scroll tw-overflow-y-auto tw-mt-3">
      <div v-if="tasks && !isLoadingAnalytics" class="tw-bg-slate-400/15 tw-rounded-xl tw-p-2">
        <div class="tw-flex tw-justify-between tw-items-center tw-pb-2">
          <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-2">{{ $t('Tasks') }}</h4>
          <div class="table-header-container tw-pr-2">
            <Button class="mr-2" icon="pi pi-plus" label="Expand All" @click="expandAll"> Expand All</Button>
            <Button icon="pi pi-minus" label="Collapse All" @click="collapseAll">Collapse All</Button>
          </div>
        </div>
        <DataTable
          ref="tasks"
          :expandedRows.sync="expandedRows"
          :rows="8"
          :selection.sync="selectedTasks"
          :value="filterTasks"
          class="p-datatable-sm tw-mb-0"
          paginator
          responsiveLayout="scroll"
          @row-expand="onRowExpand"
        >
          <template #paginatorstart></template>
          <template #paginatorend>
            <div class="tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer" @click="exportCSV('tasks')">
              <span>CSV</span>
              <svg
                class="tw-w-6 tw-h-6"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
          <Column
            :body-style="{paddingLeft: '15px'}"
            :header-style="{width: '40px', paddingLeft: '15px'}"
            selectionMode="multiple"
          ></Column>
          <Column :expander="true" :headerStyle="{width: '40px'}" />
          <Column :headerStyle="{width: '40px'}" header="#">
            <template #body="slotProps">
              <b-badge :variant="`light-${resolveTypeVariant(slotProps.data.task_type)}`">
                {{ '#' + slotProps.data.id }}
              </b-badge>
            </template>
          </Column>
          <Column field="subject" frozen header="Subject" sortable>
            <template #body="slotProps">
              <a
                v-tippy="{content: slotProps.data.subject, theme: 'light-border', delay: 1000}"
                :href="$router.resolve({name: 'task', params: {id: slotProps.data.id}}).href"
                class="tw-block tw-text-gray-600 hover:tw-underline tw-w-[500px] tw-truncate"
                target="_blank"
              >
                {{ slotProps.data.subject }}
              </a>
            </template>
          </Column>
          <Column :header-style="{width: '110px'}" field="task_type" header="Task type" sortable>
            <template #body="slotProps">
              {{ wordToUpperCase(slotProps.data.task_type) }}
            </template>
          </Column>
          <Column :header-style="{width: '130px'}" field="worker_type" header="Worker type" sortable>
            <template #body="slotProps">
              {{ wordToUpperCase(slotProps.data.worker_type) }}
            </template>
          </Column>
          <Column :header-style="{width: '90px'}" field="assigned.title" header="Assigned" sortable></Column>
          <Column :header-style="{width: '90px'}" field="performer.title" header="Performer" sortable></Column>
          <Column :header-style="{width: '80px'}" field="status.status" header="Status" sortable>
            <template #body="slotProps">
              <b-badge
                :variant="`light-${colorStatus(slotProps.data.status.status)}`"
                class="rounded-lg tw-py-1 tw-px-2 tw-text-xs font-weight-bolder tw-whitespace-nowrap tw-uppercase"
              >
                {{ wordToUpperCase(slotProps.data.status.status).split('_').join(' ') }}
              </b-badge>
            </template>
          </Column>
          <Column :header-style="{width: '150px'}" field="estimate" header="Estimate time" sortable>
            <template #body="slotProps">
              {{ slotProps.data.estimate ? getTimeFromMinutes(slotProps.data.estimate) : '-' }}
            </template>
          </Column>
          <Column :header-style="{width: '150px'}" field="time_elapsed" header="Time elapsed" sortable>
            <template #body="slotProps">
              {{ slotProps.data.time_elapsed ? getTimeFromMinutes(slotProps.data.time_elapsed) : '-' }}
            </template>
          </Column>
          <template #expansion="slotProps">
            <div v-if="slotProps.data.notes.length" class="tw-bg-slate-400/20 tw-rounded-xl tw-p-2 tw-my-2">
              <DataTable
                :value="slotProps.data.notes.filter(note => isBetween(note.created_at))"
                class="p-datatable-sm tw-mb-0"
                responsiveLayout="scroll"
              >
                <Column :header-style="{width: '90px'}" field="author.title" header="Assigned" sortable></Column>
                <Column :header-style="{width: '90px'}" field="author.worker_type" header="Worker Type" sortable>
                  <template #body="slotProps">
                    {{ wordToUpperCase(slotProps.data.author.worker_type) }}
                  </template>
                </Column>
                <Column :header-style="{width: '90px'}" field="created_at" header="Created at" sortable>
                  <template #body="slotProps">
                    {{ dayjs(slotProps.data.created_at).format('MM/DD/YYYY HH:mm') }}
                  </template>
                </Column>
                <Column :header-style="{width: '150px'}" field="time_elapsed" header="Time elapsed" sortable>
                  <template #body="slotProps">
                    {{ slotProps.data.time_elapsed ? getTimeFromMinutes(slotProps.data.time_elapsed) : '-' }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>

      <div class="tw-flex tw-gap-6 tw-w-full tw-mt-6 max-md:tw-flex-col">
        <div v-if="notes && !isLoadingAnalytics" class="md:tw-w-2/3 tw-bg-slate-400/15 tw-rounded-xl tw-p-2">
          <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-4">{{ $t('Users') }}</h4>
          <DataTable
            ref="users"
            :rows="5"
            :selection.sync="selected"
            :value="filterNotes"
            class="p-datatable-sm tw-mb-0"
            paginator
            responsiveLayout="scroll"
          >
            <Column
              :body-style="{paddingLeft: '15px'}"
              :header-style="{width: '40px', paddingLeft: '15px'}"
              selectionMode="multiple"
            ></Column>
            <Column :header-style="{width: '90px'}" field="author.title" header="User" sortable></Column>
            <Column :header-style="{width: '90px'}" field="author.worker_type" header="Worker Type" sortable>
              <template #body="slotProps">
                {{ wordToUpperCase(slotProps.data.author.worker_type) }}
              </template>
            </Column>
            <Column :header-style="{width: '80px'}" field="time_elapsed" header="Time elapsed" sortable>
              <template #body="slotProps">
                {{ slotProps.data.time_elapsed ? getTimeFromMinutes(slotProps.data.time_elapsed) : '-' }}
              </template>
            </Column>
            <Column :header-style="{width: '80px'}" field="taskQuantity" header="Number of tasks" sortable>
              <template #body="slotProps">
                {{ filterTasks.filter(task => task.assigned.id === slotProps.data.author.id).length }}
              </template>
            </Column>
            <Column :header-style="{width: '80px'}" field="closed_tasks" header="Closed tasks" sortable>
              <template #body="slotProps">
                {{
                  filterTasks.filter(
                    task => task.assigned.id === slotProps.data.author.id && task.status.status === 'done'
                  ).length
                }}
              </template>
            </Column>
            <template #paginatorstart></template>
            <template #paginatorend>
              <div class="tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer" @click="exportCSV('users')">
                <span>CSV</span>
                <svg
                  class="tw-w-6 tw-h-6"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </DataTable>
        </div>

        <div v-if="!isLoadingAnalytics" class="md:tw-w-1/3 tw-bg-slate-400/15 tw-rounded-xl tw-p-2">
          <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-4">{{ $t('Total') }}</h4>

          <p class="tw-p-2 tw-font-medium tw-text-gray-700">
            Total time:
            <span class="tw-font-semibold">{{
              getTimeFromMinutes(selectedTasks.reduce((s, i) => (s = s + i.time_elapsed), 0))
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BBadge} from 'bootstrap-vue';
import {colorStatus, formatEstimate, resolveTypeVariant, wordToUpperCase} from '@/helpers/handlers';

import DatePicker from 'vue2-datepicker';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import DataTable from 'primevue/datatable/DataTable';
import Column from 'primevue/column/Column';
import {getTimeFromMinutes} from '@/assets/scripts/scripts';
import TasksService from '@/services/tasks.service';
import OsLoader from '@/components/OsLoader.vue';
import UIDropdown from '@/components/UIDropdown.vue';
import SprintsService from '@/services/sprints.service';
import moment from 'moment';
import dayjs from 'dayjs';
import store from '@/store';

export default {
  computed: {
    dayjs() {
      return dayjs;
    },
    sprints() {
      const list = this.$store.state.osBoard['sprints'];
      return list ? [...list] : [];
    },
    filterTasks() {
      return this.tasks.filter(task =>
        this.dateRange[0] && this.dateRange[1]
          ? task.notes.filter(note => this.isBetween(note.created_at)).length
          : true
      );
    },
    filterNotes() {
      return this.notes
        .filter(note => (this.selectedUser ? note.author.id === this.selectedUser.value : true))
        .filter(note => this.isBetween(note.created_at));
    },
    project: {
      get: () => store.state.osBoard.selectedProject,
      set: val => {
        localStorage.setItem('selected_project', JSON.stringify(val));
        store.commit('osBoard/SET_PROJECT', val);
      },
    },
  },
  components: {UIDropdown, OsLoader, BBadge, DataTable, Column, DatePicker},
  data() {
    return {
      fields: [
        {key: 'user', sortable: true},
        {key: 'workerType', sortable: true},
        {key: 'hours', sortable: true},
        {key: 'taskQuantity', sortable: true},
      ],
      fieldsTasks: [
        {key: 'user', sortable: true},
        {key: 'workerType', sortable: true},
        {key: 'hours', sortable: true},
        {key: 'taskQuantity', sortable: true},
      ],
      sprint: null,
      tasks: [],
      notes: [],
      users: [],
      statuses: [],
      selected: [],
      selectedTasks: [],
      selectedUser: null,
      selectedStatus: null,
      selectedSprint: null,
      dateRange: [null, null],
      expandedRows: [],
      isLoadingAnalytics: false,
    };
  },
  mounted() {
    if (['ivan.a', 'nikita.s', 'aleksandr.r', 'oleg.m'].includes(this.$store.state.osBoard.user.username)) {
      this.getSprint();
    } else {
      this.$router.push({name: 'planning'});
    }
  },
  methods: {
    formatEstimate,
    wordToUpperCase,
    getTimeFromMinutes,
    resolveTypeVariant,
    colorStatus,
    toUNIX(date, addDay = false) {
      return addDay ? dayjs(date).add(1, 'day').unix() : dayjs(date).unix();
    },
    getSprint() {
      this.isLoadingAnalytics = true;
      const id = this.selectedSprint ? this.selectedSprint.value : this.$route.query.id;
      SprintsService.getSprint(id).then(r => {
        this.sprint = r.data;
      });
      TasksService.getTasksForSprint(id).then(r => {
        this.tasks = r.data
          .filter(task => (this.selectedUser ? task.assigned.id === this.selectedUser.value : true))
          .filter(task => (this.selectedStatus ? task.status.id === this.selectedStatus.value : true));
        this.getNotes();
      });
    },
    getNotes() {
      this.notes = [];
      this.tasks.forEach((item, i) => {
        if (this.users.findIndex(e => e.value === item.assigned.id) === -1)
          this.users.push({
            title: item.assigned.title,
            value: item.assigned.id,
          });

        if (this.statuses.findIndex(e => e.value === item.status.id) === -1)
          this.statuses.push({
            title: this.wordToUpperCase(item.status.status).split('_').join(' '),
            value: item.status.id,
          });

        this.tasks[i].notes = [];
        this.tasks[i].time_elapsed = 0;

        TasksService.getNotes(item.id).then(r => {
          r.data
            .filter(note => this.isBetween(note.created_at))
            .forEach(el => {
              this.tasks[i].time_elapsed += el.time_elapsed;

              if (this.notes.find(e => e.author.id === el.author.id)) {
                this.notes.find(e => e.author.id === el.author.id).time_elapsed += el.time_elapsed;
              } else {
                if (this.isBetween(el.created_at)) this.notes.push({...el, taskQuantity: 1});
              }

              if (this.tasks[i].notes.find(e => e.author.id === el.author.id)) {
                this.tasks[i].notes.find(e => e.author.id === el.author.id).time_elapsed += el.time_elapsed;
              } else {
                if (this.isBetween(el.created_at)) this.tasks[i].notes.push(el);
              }
            });

          if (this.tasks.length === i + 1) this.isLoadingAnalytics = false;
        });
      });
    },
    exportCSV(ref) {
      this.$refs[ref].exportCSV();
    },
    onRowExpand(event) {
      console.log(event.data);
    },
    expandAll() {
      this.expandedRows = this.tasks.filter(p => p.time_elapsed);
      this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatDateTime(date) {
      if (this.$i18n.locale === 'ru') {
        moment.locale('ru');
        return moment(date).format('D MMM YYYY');
      } else {
        moment.locale('en');
        return moment(date).format('MM/DD/YYYY HH:mm');
      }
    },
    isBetween(date) {
      if (this.dateRange[0] && this.dateRange[1]) {
        return (
          this.toUNIX(date) > this.toUNIX(this.dateRange[0]) && this.toUNIX(date) < this.toUNIX(this.dateRange[1], true)
        );
      } else {
        return true;
      }
    },
    onSelectSprint() {
      this.getSprint();
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #6f6c7c;
$primary-color: #0095e8;

@import '~vue2-datepicker/scss/index.scss';

.range-date-input {
  .xmx-input {
    border: 1px solid #9ca3af !important;
    height: 31.5px;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
  }

  svg {
    color: #9ca3af !important;
  }
}

.p-component {
  font-family: 'Montserrat', serif;

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > th {
    color: unset;
  }
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #f3f4f6;
}

.p-checkbox {
  box-shadow: none !important;

  .p-checkbox-box {
    border-radius: 6px;
    box-shadow: none;
  }
}

.p-datatable .p-sortable-column.p-highlight {
  color: #0ea5e9;

  &:hover {
    color: #0ea5e9;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0ea5e9;
  background-color: #0ea5e9;
  box-shadow: none;
  padding: 4px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #0ea5e9;
  color: #fff;
  border-radius: 8px;
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 8px;
  min-width: 2rem;
  height: 2rem;
  box-shadow: none;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 0;
}

.p-contextmenu {
  position: fixed !important;
  display: unset !important;
}

.b-table-select-multi {
  .b-table-row-selected {
    > td {
      background-color: #dadaea;
    }
  }

  td,
  th {
    padding: 0.42rem 0.8rem;
  }
}
</style>
